<template>
  <div class="container">
    <h1 class="title">Hi,同学</h1>
    <div class="title-en">欢迎您</div>

    <van-form @submit="onSubmit">
      <div class="wbg">
        <van-field
          v-model="phone"
          type="digit"
          name="phone"
          placeholder="手机号码"
          :rules="[{ required: true, message: '请填写您的手机号码' }]"
        />
        <van-row>
          <van-col span="16"
            ><van-field
              v-model="code"
              type="digit"
              name="code"
              placeholder="验证码"
              :rules="[{ required: true, message: '请填写6位手机验证码' }]"
          /></van-col>
          <van-col span="8">
            <van-button
              style="margin-top: 10px"
              plain
              round
              block
              size="small"
              type="default"
              native-type="button"
              @click="getSmsCode"
              >{{ codeText }}</van-button
            >
          </van-col>
        </van-row>
      </div>
      <div style="margin: 30px 16px 16px 16px">
        <van-button
          round
          block
          :disabled="disabled"
          type="danger"
          native-type="submit"
          >注册或登录</van-button
        >
      </div>
    </van-form>
    <div class="agree">
      <van-checkbox v-model="agree" shape="square" icon-size="16px" />
      <span
        >已阅读并同意<b @click="navTo('/login/userAgreement')">《用户协议》</b
        >和<b @click="navTo('/login/privacyPolicy')">《隐私政策》 </b></span
      >
    </div>
    <div class="info">若您之前没有登录过，系统将为您创建一个新账号</div>
    <p class="slogo">© {{ year }} 广西外国语学院</p>
  </div>
</template>
<script>
import * as api from "@/apis/api";
import { Toast } from "vant";
import { Notify } from "vant";
export default {
  data() {
    return {
      phone: "",
      code: "",
      codeText: "获取验证码",
      disabled: false,
      time: 120,
      canSendSms: true,
      year: new Date().getFullYear(),
      agree: false,
    };
  },
  methods: {
    navTo(url) {
      this.$router.push(url);
    },
    onSubmit(values) {
      if (this.agree == false) {
        Notify({
          type: "danger",
          message: "请阅读并同意《用户协议》和《隐私政策》",
        });
        return false;
      }
      this.disabled = true;
      api.login(values).then((res) => {
        if (res.state == "ok") {
          // localStorage.setItem("token", res.msg.token);
          // localStorage.setItem("user", JSON.stringify(res.msg.user));
          this.$store.commit("setUser", res.msg.user);
          this.$store.commit("setToken", res.msg.token);
          // this.$router.go(-1);
          // this.$router.push("/");
          api.redirect().then((res) => {
            location.href = res.msg;
          });
        } else {
          Notify({ type: "danger", message: res.msg });
        }
        this.disabled = false;
      });
    },
    getSmsCode() {
      if (!this.canSendSms) {
        return;
      }
      this.canSendSms = false;
      let phone = this.phone;
      if (phone.length != 11) {
        Toast.fail("手机号码格式错误");
        this.canSendSms = true;
        return;
      }
      api.sendSms({ phone: phone }).then((res) => {
        if (res.state == "ok") {
          this.disabled = false;
          this.canSendSms = false;
          let clock = window.setInterval(() => {
            this.time--;
            if (this.time < 0) {
              this.canSendSms = true;
              this.second = 120;
              this.codeText = "重新发送";
              clearInterval(clock);
            } else {
              this.codeText = "等待" + this.time + "秒";
            }
          }, 1000);
        } else {
          this.canSendSms = true;
          Notify({ type: "danger", message: res.msg });
        }
      });
    },
  },
};
</script>
<style scoped>
.container {
  padding: 50px 5%;
}
.wbg {
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
}
.title {
  margin: 10px;
  color: #333;
}
.title-en {
  margin: 10px 10px 50px 10px;
  color: #666;
}
.info {
  font-size: 12px;
  text-align: center;
  color: #666;
}
.slogo {
  position: fixed;
  bottom: 0;
  width: 90%;
  text-align: center;
  color: #555;
  font-size: 12px;
}
.agree {
  font-size: 12px;
  display: flex;
  margin-bottom: 20px;
  justify-content: center;
}
.agree span {
  padding-left: 5px;
}
.agree span b {
  font-weight: normal;
  color: #c12b35;
}
</style>
